<template>
  <span
    >&nbsp; =
    {{
      `${formatTime(this.numberOfSeconds).hour} hours, ${
        formatTime(this.numberOfSeconds).minute
      } minutes, ${formatTime(this.numberOfSeconds).second} seconds`
    }}</span
  >
</template>
<script>
import formatTime from '@/helpers/formatTime';

export default {
  props: {
    numberOfSeconds: {
      type: Number,
    },
  },
  methods: {
    formatTime,
  },
};
</script>
<style></style>
