<template>
  <div v-loading="this.isLoading">
    <el-header height="300">
      <el-row :gutter="20" type="flex" align="middle" justify="center">
        <el-col :span="6" :offset="0">
          <el-button type="danger" v-if="currentData.LoopMachineState === `Activating`" @click="handleChangeState(false)">
            Activing - Click to hold
          </el-button>
          <el-button type="primary" v-if="currentData.LoopMachineState === `Holding`" @click="handleChangeState(true)">
            Holding - Click to Active
          </el-button>
        </el-col>
        <el-col :offset="0">
          <h1>THE NEXT EVENT WILL BE SET UP AUTOMATICALLY <span class="red-text">{{ this.nextSetUpCountDown }}</span></h1>
        </el-col>
        <el-col :span="10" :offset="0">
          <el-row>
            <el-button
                v-if="currentErrorMsg.length === 0"
                type="success"
                size="default"
                @click="handleApplyData"
            >Apply Data
            </el-button>
            <el-button
                v-if="currentErrorMsg.length > 0"
                type="danger"
                size="default"
                @click="doNothingFunction"
            >Apply Data
            </el-button>
          </el-row>
          <el-row>
            <ul>
              <li  v-for="msg in this.currentErrorMsg" :key="msg">
                {{ msg }}
              </li>
            </ul>
          </el-row>

        </el-col>
      </el-row>
    </el-header>

    <el-tabs type="border-card" tab-position="top" v-model="tabActive">
      <!-- DYNAMIC EVENT -->
      <el-tab-pane label="DYNAMIC EVENT" name="DYNAMIC EVENT">
        <el-main>
          <h1>DYNAMIC EVENT</h1>
          <el-form ref="form" :model="loopEventRaidBossForm" label-width="300px">
            <MyFormItem label="Time Start" tooltip="Thời gian bắt đầu của Event gần nhất tiếp theo (giây)">
              <el-date-picker
                  v-model="dateInputTimeStart"
                  type="datetime"
                  value-format="timestamp"
                  placeholder="Select date and time"
              >
              </el-date-picker>
            </MyFormItem>

            <MyFormItem label="Time Start Server" tooltip="Thời gian bắt đầu của Event gần nhất tiếp theo (giây)">
              <el-date-picker
                  v-model="dateInputTimeStartServer"
                  type="datetime"
                  disabled
                  value-format="timestamp"
                  placeholder="Select date and time"
              >
              </el-date-picker>
            </MyFormItem>

            <MyFormItem label="Event Duration" tooltip="Thời gian kéo dài của event (giây)">
              <TimeInput :value="currentData.EventDuration" :on-change="handleOnEventDurationChange"/>
            </MyFormItem>

            <MyFormItem  label="Time Delay" tooltip="Thời gian chờ đến lúc lên Event tiếp theo (giây)">
              <TimeInput :value="currentData.TimeDelay" :on-change="handleOnTimeDelayChange"/>
            </MyFormItem>

            <MyFormItem label="Coming soon before" tooltip="Thời gian Coming Soon trước Time Start bao lâu (giây)">
              <TimeInput :value="currentData.ComingSoonBefore" :on-change="handleOnComingSoonBeforeChange"/>
            </MyFormItem>

            <MyFormItem label="Setup before" tooltip="Thời gian Setup trước Time Start bao lâu (giây)">
              <TimeInput :value="currentData.SetupBefore" :on-change="handleOnSetupBeforeChange"/>
            </MyFormItem>

            <MyFormItem label="Affect From Version" tooltip="event xuất hiện từ phiên bản nào trở lên">
              <el-input class="form-input" v-model="currentData.AffectFromVersion" placeholder="Example: 1.65.0" size="normal" clearable></el-input>
            </MyFormItem>

            <MyFormItem label="Affect To Version" tooltip="event xuất hiện từ phiên bản nào trở lên">
              <el-input class="form-input" v-model="currentData.AffectToVersion" placeholder="Example: 1.65.0" size="normal" clearable></el-input>
            </MyFormItem>

            <MyFormItem label="Current Index Config" tooltip="Index config sử dụng cho đợt event sau">
              <el-input-number v-model="currentData.CurrentIndexConfig" size="normal" label="label" :step="1" :controls="true" :min="0" :max="currentData.MaxIndexConfig - 1"/>
            </MyFormItem>

            <MyFormItem label="Max Index Config" tooltip="Số lượng config có trong bundle">
              <el-input-number v-model="currentData.MaxIndexConfig" size="normal" label="label" :step="1" :controls="true" :min="1"/>
            </MyFormItem>

            <MyFormItem label="EventName Format" tooltip="EventName ví dụ: RAID BOSS OPERATION {MM/DD}">
              <el-input class="form-input" v-model="currentData.EventNameFormat" size="normal"></el-input>
            </MyFormItem>

          </el-form>
          <step-button nextTab="LEADERBOARD" @onChangeTabTo="handleChangeTabTo"/>
        </el-main>
      </el-tab-pane>

      <!-- LEADERBOARD -->
      <el-tab-pane label="LEADERBOARD" name="LEADERBOARD">
        <el-main>
          <h1>LEADERBOARD</h1>

          <upload-title>Reward List</upload-title>
          <el-form ref="form" label-width="300px">
            <MyFormItem label="Reward Files" tooltip="Đẩy các file reward lên (mini_1, mini_2, total, ...)">
              <el-row :gutter="20" type="flex" justify="start">
                <el-col :offset="0">
                  <el-upload
                      drag
                      action=""
                      :auto-upload="false"
                      :on-change="handleChangeRewardFileList"
                      :on-remove="handleRemoveRewardFileList"
                      :file-list="rewardFileList"
                      class="file-list-item">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                  </el-upload>
                </el-col>
                <el-col :offset="0">
                  <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                    <el-button size="default" @click="handleDownloadCurrentRewardData"><i class="el-icon-download"></i> Download current config </el-button>
                  </el-tooltip>
                </el-col>
              </el-row>
            </MyFormItem>

            <el-collapse :accordion="false" v-if="rewardFileShow.length > 0">
              <el-collapse-item title="View Imported Data">
                <el-table :data="rewardFileShow" border>
                  <el-table-column
                      prop="index"
                      label="index">
                  </el-table-column>
                  <el-table-column
                      prop="LoopSet"
                      label="LoopSet">
                  </el-table-column>
                  <el-table-column
                      prop="Title"
                      label="Title">
                  </el-table-column>
                  <el-table-column
                      prop="RankMin"
                      label="RankMin">
                  </el-table-column>
                  <el-table-column
                      prop="RankMax"
                      label="RankMax">
                  </el-table-column>
                  <el-table-column
                      prop="CoinRequire"
                      label="CoinRequire">
                  </el-table-column>
                  <el-table-column
                      prop="Gifts"
                      label="Gifts">
                  </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>
          </el-form>

          <upload-title >User segments</upload-title>
          <el-form ref="form" label-width="300px">
            <MyFormItem label="User Segments" tooltip="Đẩy file User Segment lên">
              <el-row :gutter="20" type="flex" justify="start">
                <el-col  :offset="0">
                  <el-upload
                      drag
                      action=""
                      :auto-upload="false"
                      :file-list="userSegmentFileList"
                      :on-change="handleChangeUserSegmentFileList"
                      :on-remove="handleRemoveUserSegmentFileList"
                      class="file-list-item">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                  </el-upload>
                </el-col>
                <el-col  :offset="0">
                  <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                    <el-button size="default" @click="handleDownloadCurrentUserSegmentData"><i class="el-icon-download"></i> Download curent config</el-button>
                  </el-tooltip>

                  <el-tooltip content="Các thông tin cần biết về User Segment" placement="top-start" effect="dark">
                    <el-button size="default" @click="isDialogUserSegmentInfoVisible = true"><i class="el-icon-info"></i>Information</el-button>
                  </el-tooltip>
                  <el-dialog title="User Segment Information" :visible.sync="isDialogUserSegmentInfoVisible">
                    <h2>User Segment Types</h2>
                    <el-table :data="userSegmentInfo.types" border>
                      <el-table-column property="key" label="key"></el-table-column>
                      <el-table-column property="info" label="info"></el-table-column>
                    </el-table>
                  </el-dialog>
                </el-col>
              </el-row>
            </MyFormItem>

            <el-collapse :accordion="false" v-if="currentData.SegmentData.length > 0">
              <el-collapse-item title="View Imported Data">
                <el-table :data="currentData.SegmentData" border>
                  <el-table-column
                      prop="name"
                      label="Name"
                      width="400">
                  </el-table-column>
                  <el-table-column
                      prop="count"
                      label="Count">
                  </el-table-column>
                  <el-table-column
                      prop="size"
                      label="Size">
                  </el-table-column>
                  <el-table-column
                      prop="payment_size"
                      label="Payment Size">
                  </el-table-column>
                  <el-table-column
                      prop="none_payment_limit_size_1"
                      label="Non Payment">
                  </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>
          </el-form>

        </el-main>
        <step-button previousTab="DYNAMIC EVENT" nextTab="BOSS INFORMATION" @onChangeTabTo="handleChangeTabTo"/>
      </el-tab-pane>

      <!-- CLAN -->
      <el-tab-pane label="CLAN" name="CLAN">
        <upload-title>Clan Reward Config</upload-title>
        <el-form label-width="300px">
          <MyFormItem label="Clan Reward File" tooltip="Đẩy file Clan Reward lên">
            <el-row :gutter="20" type="flex" justify="start">
              <el-col  :offset="0">
                <el-upload
                    drag
                    action=""
                    :file-list="clanRewardFileList"
                    :auto-upload="false"
                    ref=""
                    :on-change="handleChangeClanRewardFileList"
                    :on-remove="handleRemoveClanRewardFileList"
                    class="file-list-item">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                </el-upload>
              </el-col>
              <el-col  :offset="0">
                <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                  <el-button size="default" @click="handleDownloadCurrentClanRewardFile"><i class="el-icon-download"></i> Download current config</el-button>
                </el-tooltip>
              </el-col>
            </el-row>
          </MyFormItem>

          <el-collapse class="space-to-bottom" :accordion="false" v-if="currentData.ClanRewardData.length > 0">
            <el-collapse-item title="View Imported Data">
              <el-table :data="currentData.ClanRewardData" border>
                <el-table-column
                    prop="id"
                    label="Index"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="title"
                    label="Title">
                </el-table-column>
                <el-table-column
                    prop="rankMin"
                    label="RankMin">
                </el-table-column>
                <el-table-column
                    prop="rankMax"
                    label="RankMax">
                </el-table-column>
                <el-table-column
                    prop="requiredScore"
                    label="RequireScore">
                </el-table-column>
                <el-table-column
                    prop="rewards"
                    label="Rewards">
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-form>

        <upload-title>Clan Segment Config</upload-title>
        <el-form label-width="300px">
          <MyFormItem label="Clan Segment File" tooltip="Đẩy file LeaderBoardClanSegmentConfig.mlb lên">
            <el-row :gutter="20" type="flex" justify="start">
              <el-col  :offset="0">
                <el-upload
                    drag
                    action=""
                    :file-list="clanSegmentFileList"
                    :auto-upload="false"
                    ref=""
                    :on-change="handleChangeClanSegmentFileList"
                    :on-remove="handleRemoveClanSegmentFileList"
                    class="file-list-item">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                </el-upload>
              </el-col>
              <el-col  :offset="0">
                <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                  <el-button size="default" @click="handleDownloadCurrentClanSegmentFile"><i class="el-icon-download"></i> Download current config</el-button>
                </el-tooltip>
              </el-col>
            </el-row>
          </MyFormItem>

          <el-collapse class="space-to-bottom" :accordion="false" v-if="this.currentData.ClanSegmentData.length > 0">
            <el-collapse-item title="View Imported Data">
              <el-table :data="this.currentData.ClanSegmentData" border>
                <el-table-column
                    prop="name"
                    label="GroupName">
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="TotalLDB">
                </el-table-column>
                <el-table-column
                    prop="size"
                    label="TotalClan">
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-form>

        <step-button previousTab="BOSS INFORMATION" @onChangeTabTo="handleChangeTabTo"/>


      </el-tab-pane>

      <!-- Log -->
    </el-tabs>
  </div>
</template>
<script>
import moment from "moment";

import './index.scss'

import StepButton from './../component/stepButton.vue'
import UploadTitle from './../component/uploadTitle.vue'
import TableForm from '@/components/TableForm/index.vue'
import MyFormItem from '@/views/loop-machine/component/myFormItem.vue'
import TimeInput from './../component/timeInput.vue'
import { getOSToken } from '@/utils/auth'
import { MessageBox, Message } from "element-ui";

import changeObjectKeys from '@/helpers/changeObjectKeys'
import downloadFile from '@/helpers/downloadFile'
import generateMLBFileContent from '@/helpers/generateMLBFileContent'
import parseMLBfile from '@/helpers/parseMLBfile'
import readFile from '@/helpers/readFile'

import request from "@/utils/request-service-admin";

export default {
  data() {
    return {
      // Common
      loopEventRaidBossForm: {},
      tabActive: 'DYNAMIC EVENT',
      isLoading: true,

      //server data
      loopEndlessData: {
        TimeStart: 0,
        EventDuration: 0,
        TimeDelay: 0,
        SetupBefore: 0,
        ComingSoonBefore: 0,
        AffectFromVersion: "",
        AffectToVersion: "",
        CurrentIndexConfig: 0,
        MaxIndexConfig: 2,
        RewardData: [],
        SegmentData: [],
        ClanRewardData: [],
        ClanSegmentData: [],
        EventNameFormat: "RAID BOSS EVENT {MM/DD}",
        LoopMachineState: "Holding",
      },

      countDownNextSetUp: 0,

      //dynamic event
      dateInputTimeStartServer: 0,

      // Leaderboard
      isDialogUserSegmentInfoVisible: false,

      userSegmentInfo: {
        types: [
          {
            key: 'us_region_lowerequal_4',
            info: 'all users in the tier1 countries: US, GB, FR, DE, IT, AU, CA, KR, JP, TW, HK'
          },
          {
            key: 'us_region_greater_4',
            info: 'all users in the tier1 countries: US, GB, FR, DE, IT, AU, CA, KR, JP, TW, HK'
          },
          {
            key: 'tier3_region_lowerequal_4',
            info: 'all users in the tier3 countries: IN, PH, ID'
          },
          {
            key: 'tier3_region_greater_4',
            info: 'all users in the tier3 countries: IN, PH, ID'
          },
          {
            key: 'others_region_lowerequal_4',
            info: 'other users'
          },
          {
            key: 'others_region_greater_4',
            info: 'other users'
          },
          {
            key: 'cheaters',
            info: 'Cheaters will be moved to this leaderboard'
          },
          {
            key: 'testers',
            info: 'For QC, Dev, ... to test event'
          },
        ]
      },

      // Clan
      debounceTimeruploadClanRewardFileList: {},
      debounceTimeruploadClanSegmentFileList: {},
    }
  },
  methods: {
    // Common
    handleChangeTabTo(tabName) {
      this.tabActive = tabName
    },
    doNothingFunction(){
    },
    IsIOS() {
      let OS = getOSToken();
      return OS && OS.toLowerCase() === 'ios';
    },
    updateCountDownTime(){
      this.countDownNextSetUp = (this.loopEndlessData.TimeStart - this.loopEndlessData.SetupBefore) - moment().unix();
    },
    parseDataToView() {
      this.dateInputTimeStartServer = this.currentData.TimeStart * 1000;
      this.updateCountDownTime();
    },

    async handleChangeState(isActive) {
      this.isLoading = true;

      try {
        let response = await request({
          url: `/admintool/loop-machine/update_state/Endless`,
          method: `post`,
          data: {isActive: isActive},
        });

        await this.getDataFromServer();

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.isLoading = false;
      }catch (ex) {
        Message({
          message: `Error ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.isLoading = false;
      }


    },

    async getDataFromServer() {
      let data = await request({
        url: `/admintool/loop-machine/data/Endless`,
        method: `get`
      });

      this.currentData = data;
      this.parseDataToView();
    },
    async handleApplyData() {
      this.isLoading = true;

      try {
        let response = await request({
          url: `/admintool/loop-machine/update_data/Endless`,
          method: `post`,
          data: this.loopEndlessData,
        });

        await this.getDataFromServer();

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.isLoading = false;
      } catch (ex) {
        Message({
          message: `Error ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.isLoading = false;
      }
    },

    AwaitDelay(ms){
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      })
    },

    async autoCountDown() {
      let breakFunction = false;
      while (!breakFunction) {
        if (this.countDownNextSetUp > 0) {
          this.countDownNextSetUp -= 1;
        }

        await this.AwaitDelay(1000);
      }
    },

    // Dynamic
    handleOnEventDurationChange(value){
      this.currentData.EventDuration = value;
    },
    handleOnTimeDelayChange(value){
      this.currentData.TimeDelay = value;
    },
    handleOnComingSoonBeforeChange(value){
      this.currentData.ComingSoonBefore = value;
    },
    handleOnSetupBeforeChange(value){
      this.currentData.SetupBefore = value;
      this.updateCountDownTime();
    },

    // Leaderboard
    generalMlbContentOfReward(listData) {
      let dataList = [];
      for (let loopIndex = 0; loopIndex < listData.length; loopIndex++) {
        for (let row = 0; row < listData[loopIndex].length; row++) {
          let data = listData[loopIndex][row];
          let tempString = `${data.id}\t${loopIndex}\t${data.title}\t${data.rankMin}\t${data.rankMax}\t${data.requiredPoint}\t${data.gifts}`;
          dataList.push(tempString);
        }
      }

      return generateMLBFileContent(`LeaderBoardGiftsConfig`,
          ["index", "LoopSet", "Title", "RankMin", "RankMax", "CoinRequire", "Gifts"],
          ["int", "int", "string", "int", "int", "int", "string"],
          dataList);
    },

    generalMlbContentOfUserSegment(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.name}\t${data.count}\t${data.payment_size}\t${data.none_payment_limit_size_1}\t${data.size}`;
        dataList.push(tempString);
      }

      return generateMLBFileContent(`LeaderBoardSegmentConfig`,
          ['Name', 'Count', 'PaymentSize', 'NonPayment', 'TotalSize'],
          ['string', 'int', 'int', 'int', 'int'],
          dataList);
    },

    generateMLBFileContentOfClanReward(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.id}\t${data.title}\t${data.rankMin}\t${data.rankMax}\t${data.requiredScore}\t${data.rewards}`;
        dataList.push(tempString);
      }

      return generateMLBFileContent(`LeaderBoardRewardsConfig`,
          ['index', 'Title', 'RankMin', 'RankMax', 'RequiredScore', 'Rewards'],
          ['int', 'string', 'int', 'int', 'int', 'string'],
          dataList);
    },

    generateMLBFileContentOfClanSegment(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.name}\t${data.total}\t${data.size}`;
        dataList.push(tempString);
      }

      return generateMLBFileContent(`LeaderBoardClanSegmentConfig`,
          ['GroupName', 'TotalLDB', 'TotalClan'],
          ['string', 'int', 'int'],
          dataList);
    },

    async onRewardFileListChange(fileList) {
      this.isLoading = true;
      this.loopEndlessData.RewardData = [];

      for (let i = 0; i < fileList.length; i++) {
        //not multi file
        if (i >= 1) {
          break
        }

        if (fileList[i].raw) {
          fileList[i].rawText = await readFile(fileList[i].raw);
        }

        let rewardGiftsUnMapped = parseMLBfile(fileList[i].rawText);

        let loopSet = 0;
        let loopSetArray = [];
        for (let row = 0; row < rewardGiftsUnMapped.length; row++) {
          if (parseInt(rewardGiftsUnMapped[row].LoopSet) > loopSet) {
            this.loopEndlessData.RewardData.push(loopSetArray);
            loopSetArray = [];
            loopSet++;
          }

          loopSetArray.push({
            id: rewardGiftsUnMapped[row].index,
            title: rewardGiftsUnMapped[row].Title,
            rankMin: rewardGiftsUnMapped[row].RankMin,
            rankMax: rewardGiftsUnMapped[row].RankMax,
            requiredPoint: rewardGiftsUnMapped[row].CoinRequire,
            gifts: rewardGiftsUnMapped[row].Gifts
          });

          if (row === rewardGiftsUnMapped.length - 1) {
            this.loopEndlessData.RewardData.push(loopSetArray);
          }
        }
      }

      this.isLoading = false;
    },

    async handleChangeRewardFileList(file, fileList) {
      await this.onRewardFileListChange(fileList);
    },
    async handleRemoveRewardFileList(file, fileList) {
      await this.onRewardFileListChange(fileList);
    },

    handleDownloadCurrentRewardData() {
      if (this.loopEndlessData.RewardData.length > 0) {
        downloadFile(`LeaderBoardGiftsConfig.mlb`, this.generalMlbContentOfReward(this.loopEndlessData.RewardData));
      }
    },

    async onUserSegmentFileListChange(fileList) {
      this.isLoading = true;
      this.loopEndlessData.SegmentData = [];

      const keyMap = {
        "Name": "name",
        "Count": "count",
        "TotalSize": "size",
        "PaymentSize": "payment_size",
        "NonPayment": "none_payment_limit_size_1",
      }

      for (let i = 0; i < fileList.length; i++) {
        //not multi file
        if (i >= 1) {
          break
        }

        if (fileList[i].raw) {
          fileList[i].rawText = await readFile(fileList[i].raw);
        }

        const unMappedUserSegments = parseMLBfile(fileList[i].rawText);
        const userSegments = unMappedUserSegments.map(unMappedUserSegment => changeObjectKeys(unMappedUserSegment, keyMap))

        //console.log(`unMappedUserSegments = ${JSON.stringify(userSegments, null, 4)}`);
        this.loopEndlessData.SegmentData = userSegments;
      }

      this.isLoading = false;
    },
    async handleChangeUserSegmentFileList(file, fileList) {
      await this.onUserSegmentFileListChange(fileList);
    },
    async handleRemoveUserSegmentFileList(file, fileList) {
      await this.onUserSegmentFileListChange(fileList);
    },
    handleDownloadCurrentUserSegmentData() {
      if (this.loopEndlessData.SegmentData.length <= 0) {
        return;
      }

      downloadFile(`LeaderBoardSegmentConfig.mlb`,
          this.generalMlbContentOfUserSegment(this.loopEndlessData.SegmentData));
    },

    handleOnBossActiveTimeChange(value){
      this.loopEndlessData.BossConfig.ActiveTime = value;
    },
    handleOnBossLivingTimeChange(value){
      this.loopEndlessData.BossConfig.LivingTime = value;
    },

    // Clan
    async onClanRewardFileListChange(fileList) {
      this.isLoading = true;
      this.loopEndlessData.ClanRewardData = [];

      for (let i = 0; i < fileList.length; i++) {
        //not multi file
        if (i >= 1) {
          break
        }

        if (fileList[i].raw) {
          fileList[i].rawText = await readFile(fileList[i].raw);
        }

        let clanInfoTemp = parseMLBfile(fileList[i].rawText);
        let clanInfo = [];
        for (let j = 0; j < clanInfoTemp.length; j++) {
          let data = clanInfoTemp[j];
          clanInfo.push({
            id: data.index,
            title: data.Title,
            rankMin: data.RankMin,
            rankMax: data.RankMax,
            requiredScore: data.RequiredScore,
            rewards: data.Rewards,
          });
        }

        this.loopEndlessData.ClanRewardData = clanInfo;
      }

      this.isLoading = false;
    },
    async handleChangeClanRewardFileList(file, fileList) {
      await this.onClanRewardFileListChange(fileList);
    },
    async handleRemoveClanRewardFileList(file, fileList) {
      await this.onClanRewardFileListChange(fileList);
    },
    handleDownloadCurrentClanRewardFile() {
      if (this.loopEndlessData.ClanRewardData.length <= 0) {
        return;
      }

      downloadFile('LeaderBoardGiftsConfigClan_Total.mlb',
          this.generateMLBFileContentOfClanReward(this.loopEndlessData.ClanRewardData));
    },
    helpParseRewardArray(rewardString) {
      return JSON.parse(rewardString.replace(/'/g, '"'));
    },
    async onClanSegmentFileListChange(fileList) {
      this.isLoading = true;
      this.loopEndlessData.ClanSegmentData = [];

      for (let i = 0; i < fileList.length; i++) {
        //not multi file
        if (i >= 1) {
          break
        }

        if (fileList[i].raw) {
          fileList[i].rawText = await readFile(fileList[i].raw);
        }

        let clanSegmentTemp = parseMLBfile(fileList[i].rawText);
        let clanSegment = [];
        for (let j = 0; j < clanSegmentTemp.length; j++) {
          let data = clanSegmentTemp[j];
          clanSegment.push({
            name: data.GroupName,
            total: data.TotalLDB,
            size: data.TotalClan,
          });

        }

        this.loopEndlessData.ClanSegmentData = clanSegment;
      }

      this.isLoading = false;
    },
    async handleChangeClanSegmentFileList(file, fileList) {
      await this.onClanSegmentFileListChange(fileList);
    },
    async handleRemoveClanSegmentFileList(file, fileList) {
      await this.onClanSegmentFileListChange(fileList);
    },
    handleDownloadCurrentClanSegmentFile() {
      if (this.loopEndlessData.ClanSegmentData.length <= 0) {
        return;
      }

      downloadFile('LeaderBoardClanSegmentConfig.mlb',
          this.generateMLBFileContentOfClanSegment(this.loopEndlessData.ClanSegmentData));
    },


  },

  async beforeMount() {
    await this.getDataFromServer();

    this.isLoading = false;
  },
  components: {
    UploadTitle,
    StepButton,
    //TableForm,
    MyFormItem,
    TimeInput,
  },
  computed: {
    currentData: {
      get: function() {
        return this.loopEndlessData;
      },
      set: function (value) {
        this.loopEndlessData = value;
      }
    },

    nextSetUpCountDown: {
      get: function() {
        let remainSecond = this.countDownNextSetUp;
        let totalDay = Math.floor(remainSecond / 86400);

        remainSecond = remainSecond - totalDay * 86400;
        let totalHour = Math.floor(remainSecond / 3600);

        remainSecond = remainSecond - totalHour * 3600;
        let totalMinute = Math.floor(remainSecond / 60);

        remainSecond = remainSecond - totalMinute * 60;

        let ret = "";
        if (totalDay > 0) {
          ret += `${totalDay} day`;
        }

        ret += ` ${String(totalHour).padStart(2, '0')}`
        ret += `:${String(totalMinute).padStart(2, '0')}`
        ret += `:${String(remainSecond).padStart(2, '0')}`

        return ret;
      },
    },

    bossWaitingTime() {
      return this.loopEndlessData.BossConfig.ActiveTime - this.loopEndlessData.BossConfig.LivingTime;
    },

    //Dynamic
    dateInputTimeStart: {
      get: function() {
        return this.currentData.TimeStart * 1000;
      },
      set: function(value) {
        this.currentData.TimeStart = value / 1000;
        this.updateCountDownTime();
      }
    },
    //LeaderBoard
    rewardFileList:{
      get: function () {
        let ret = [];
        if (this.loopEndlessData.RewardData.length > 0) {
          ret.push({
            name: "LeaderBoardGiftsConfig.mlb",
            rawText: this.generalMlbContentOfReward(this.loopEndlessData.RewardData)
          });
        }

        return ret;
      }
    },

    rewardFileShow: {
      get: function () {
        let ret = [];
        for (let loopSet = 0; loopSet < this.loopEndlessData.RewardData.length; loopSet++) {
          for (let row = 0; row < this.loopEndlessData.RewardData[loopSet].length; row++) {
            let data = this.loopEndlessData.RewardData[loopSet][row];
            ret.push({
              index: data.id,
              LoopSet: loopSet,
              Title: data.title,
              RankMin: data.rankMin,
              RankMax: data.rankMax,
              CoinRequire: data.requiredPoint,
              Gifts: data.gifts,
            });
          }
        }

        return ret;
      }
    },

    userSegmentFileList:{
      get: function () {
        let ret = [];

        if (this.loopEndlessData.SegmentData.length > 0) {
          ret.push(
              {
                name: `LeaderBoardSegmentConfig.mlb`,
                rawText: this.generalMlbContentOfUserSegment(this.loopEndlessData.SegmentData),
              });
        }

        return ret;
      }
    },

    //clan
    clanRewardFileList: {
      get: function() {
        let ret = [];

        if (this.loopEndlessData.ClanRewardData.length > 0) {
          ret.push(
              {
                name: `LeaderBoardGiftsConfigClan_Total.mlb`,
                rawText: this.generateMLBFileContentOfClanReward(this.loopEndlessData.ClanRewardData),
              });
        }

        return ret;
      }
    },

    clanSegmentFileList: {
      get: function() {
        let ret = [];

        if (this.loopEndlessData.ClanSegmentData.length > 0) {
          ret.push(
              {
                name: `LeaderBoardClanSegmentConfig.mlb`,
                rawText: this.generateMLBFileContentOfClanSegment(this.loopEndlessData.ClanSegmentData),
              });
        }

        return ret;
      }
    },

    currentErrorMsg: {
      get: function () {

        let ret = [];
        //dynamic Event
        let secondNow = moment().unix();
        let setupBefore = this.loopEndlessData.TimeStart - this.loopEndlessData.SetupBefore;
        if (secondNow > setupBefore) {
          ret.push(`Thời gian bắt đầu không kịp`);
        }

        if (this.loopEndlessData.CurrentIndexConfig >= this.loopEndlessData.MaxIndexConfig) {
          ret.push(`Index loop > index max`);
        }

        //leaderboard
        if (this.loopEndlessData.SegmentData.length === 0) {
          ret.push(`User Segment không có data`);
        }

        return ret;
      }
    },
  },
  created() {
    this.autoCountDown();
  },
};
</script>

<style scoped>
.red-text {
  color: red;
}
.generate-boss-list-button {
  margin-left: 252px;
  margin-bottom: 20px;
}
.space-to-bottom {
  margin-bottom: 20px;
}
</style>
