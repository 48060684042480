<template>
  <div>
    <el-input-number
      v-model="internalTime"
      size="normal"
      :label="label"
      :min="0"
      :step="1"
      :controls="true"
      controls-position="both"

    >
    </el-input-number>

    <el-select
      class="time-type-select"
      v-model="timeType"
      @change="handleTimeTypeChange"
    >
      <el-option
        v-for="item in timeOptions"
        :key="item"
        :label="item"
        :value="item"
      >
      </el-option>
    </el-select>

    <SpanTime
      :numberOfSeconds="this.internalTime * this.calculateSecondByType()"
    />
  </div>
</template>
<script>
import SpanTime from './spanTime.vue';

function noop() {}

export default {
  methods: {
    calculateSecondByType(timeType = this.timeType) {
      switch (timeType) {
        case 'second': {
          return 1;
        }
        case 'minute': {
          return 60;
        }
        case 'hour': {
          return 3600;
        }
        case 'day': {
          return 86400;
        }
        default: {
          return 1;
        }
      }
    },
    handleTimeTypeChange() {
      this.internalTime *= this.calculateSecondByType(this.previousTimeType);
      this.internalTime /= this.calculateSecondByType();
      this.previousTimeType = this.timeType;
    },
  },
  props: {
    value: {
      type: Number,
    },
    onChange: {
      type: Function,
      default: noop
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      internalTime: this.value,
      secondsBySelection: 1,
      timeType: 'second',
      timeOptions: ['second', 'minute', 'hour', 'day'],
      previousTimeType: 'second',
    };
  },
  components: {
    SpanTime,
  },
  watch: {
    internalTime(newValue) {
      let tempValue = newValue * this.calculateSecondByType();
      //console.log(`internalTime = ${newValue} -> ${tempValue}`);
      //this.$emit('input', tempValue);
      this.onChange(tempValue)
    },
    value(newValue) {
      this.internalTime = newValue / this.calculateSecondByType();
    }
  },
};
</script>
<style scoped>
.time-type-select {
  width: 100px;
}
</style>
