<template>
  <el-table :data="this.data" border stripe>
    <el-table-column
      v-for="col in this.columns"
      :prop="col.prop"
      :key="col.prop"
      :label="col.label"
      :width="col.width"
    >
      <template slot-scope="{ row }">
        <el-row :gutter="20" type="flex" justify="center" align="middle">
          <el-input
            v-if="
              col.inputType === 'text-input' ||
              !col.inputType ||
              col.inputType.length === 0
            "
            :style="{ width: `90%` }"
            v-model="row[col.prop]"
            @input="handleInput(row, col.prop)"
            @change="handleChange(row, col.prop)"
            :disabled="col.disabled"
          ></el-input>

          <el-input-number
            v-if="col && col.inputType === 'number-input'"
            v-model="row[col.prop]"
            @input="handleInput(row, col.prop)"
            @change="handleChange(row, col.prop)"
            :disabled="col.disabled"
          ></el-input-number>

          <el-select
            v-if="col && col.inputType === 'select'"
            v-model="row[col.prop]"
            :value-key="col.prop"
            filterable
            @change="handleInput(row, col.prop)"
            :style="{ width: `90%` }"
            :disabled="col.disabled"
          >
            <el-option
              v-for="item in col.options"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-row>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
// columns: {
//   prop: String,
//   label: String,
//   width: Number,
//   inputType: ["text-input", "number-input", "select"],
//   options: ["option1", "option2"]
// }

export default {
  name: 'TableForm',
  props: {
    data: {
      type: Array,
      require: true,
    },
    columns: {
      type: Array,
      require: true,
    },
  },
  methods: {
    handleInput(row, prop) {
      this.$emit('onInput', row, prop);
    },
    handleChange(row, prop) {
      this.$emit('onChange', row, prop);
    },
  },
};
</script>
<style scoped></style>
