<template>
  <el-footer>
    <el-row type="flex" justify="center" :gutter="20">
      <el-button
        v-if="previousTab && previousTab.length > 0"
        type="primary"
        size="default"
        @click="handleChangeTabTo(previousTab)"
        ><i class="el-icon-arrow-left"></i>Previous step
      </el-button>
      <el-button
        v-if="nextTab && nextTab.length > 0"
        type="primary"
        size="default"
        @click="handleChangeTabTo(nextTab)"
        >Next step<i class="el-icon-arrow-right"></i>
      </el-button>
    </el-row>
  </el-footer>
</template>
<script>
export default {
  props: {
    previousTab: {
      type: String,
    },
    nextTab: {
      type: String,
    },
  },
  methods: {
    handleChangeTabTo(tabName) {
      this.$emit('onChangeTabTo', tabName);
    },
  },
};
</script>
<style></style>
