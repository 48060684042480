<template>
  <div class="app-container">
    <el-tabs v-loading=false v-model="activeTab">
      <el-tab-pane label="Raid Boss" name="Raid Boss">
        <Raidboss/>
      </el-tab-pane>
      <el-tab-pane label="Endless" name="Endless">
        <Endless/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Raidboss from './raidboss';
import Endless from "./endless"

export default {
  name: "LoopMachine",
  components: {
    Raidboss,
    Endless,
  },
  data() {
    return {
      activeTab: "Raid Boss"
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
