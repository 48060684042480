<template>
  <div>
    <h2>
      <slot></slot>
      <span>&nbsp;</span>
      <span v-if="this.isDone" class="done-icon"
        ><i class="el-icon-success"></i>
      </span>

      <el-tooltip
        v-else-if="this.required"
        :content="this.requiredMesage"
        placement="top-start"
        effect="dark"
      >
        <span>
          <i class="el-icon-warning red-text"></i>
        </span>
      </el-tooltip>
    </h2>
  </div>
</template>
<script>
export default {
  props: {
    isDone: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredMesage: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.done-icon {
  color: #13ce66;
}
.red-text {
  color: red;
}
</style>
