function changeObjectKeys(originalObj, keyMap) {
  const transformedObj = {};

  for (const key in originalObj) {
    if (originalObj[key]) {
      const newKey = keyMap[key] || key;
      transformedObj[newKey] = originalObj[key];
    }
  }

  return transformedObj;
}

export default changeObjectKeys;

// const originalObj = {
//   "index": "1",
//   "Title": "UI_General_11",
//   "RankMin": "1",
//   "RankMax": "1",
//   "CoinRequire": "1",
//   "Gifts\r": "[ {\"code\": \"RESOURCE_TYPE.DRONE_KEY\", \"value\": 3}, {\"code\": \"RESOURCE_TYPE.WEAPON_KEY\", \"value\": 3}, {\"code\": \"RESOURCE_TYPE.GRENADE_FIRE\", \"value\": 2} ]\r"
// };

// const keyMap = {
//   "index": "ID",
//   "Title": "title",
//   "RankMin": "minRank",
//   "RankMax": "maxRank",
//   "CoinRequire": "coinRequirement",
//   "Gifts\r": "gifts"
// };

// const transformedObj = changeObjectKeys(originalObj, keyMap);
// console.log(transformedObj);
