function generateMLBFileContent(filename, columnTitles, dataTypes, data) {
  const content = `${filename}\n${columnTitles.join('\t')}\n${dataTypes.join(
    '\t'
  )}\n${data.join('\n')}`;
  return content;
}

function generateMLBFileContentByData(data) {
  const content = `${data.join('\n')}`;
  return content;
}

export default generateMLBFileContent;
export { generateMLBFileContentByData };

// const filename = 'LeaderBoardGiftsConfig';
// const columnTitles = [
//   'index',
//   'Title',
//   'RankMin',
//   'RankMax',
//   'CoinRequire',
//   'Gifts',
// ];
// const dataTypes = ['int', 'string', 'int', 'int', 'int', 'string'];
// const data = [
//   '1\tUI_General_11\t1\t1\t1\t[ {"code": "RESOURCE_TYPE.DRONE_KEY", "value": 3}, {"code": "RESOURCE_TYPE.WEAPON_KEY", "value": 3}, {"code": "RESOURCE_TYPE.GRENADE_FIRE", "value": 2} ]',
//   '2\tUI_General_11\t2\t2\t1\t[ {"code": "RESOURCE_TYPE.DRONE_KEY", "value": 2}, {"code": "RESOURCE_TYPE.WEAPON_KEY", "value": 2}, {"code": "RESOURCE_TYPE.GRENADE_FIRE", "value": 1} ]',
//   // Add more data lines as needed
// ];

// const fileContent = generateFileContent(
//   filename,
//   columnTitles,
//   dataTypes,
//   data
// );
// console.log(fileContent);
